import { useState, useEffect, useRef } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { API_URL } from "../../config";

// Props definition including state and setters for any input text
interface AudioRecorderProps {
    text: string; // The current value of the input
    setText: (value: string) => void; // Function to update the value
    cursorPosition: number; // Current cursor position in the input
}

export default function AudioRecorder({
    text,
    setText,
    cursorPosition
}: AudioRecorderProps) {
    const { botAccessToken } = useAuthContext();
    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const sendAudio = (audioBlob: Blob) => {
        const formData = new FormData();
        formData.append("file", audioBlob);

        fetch(`${API_URL}/api/v1/llms/whisper`, {
            method: 'POST',
            headers: {
                'Authorization': `Basic ${botAccessToken}`,
            },
            body: formData,
        }).then(response => {
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            return response.text();
        }).then(responseText => {
            // Parse the transcript from the response
            const transcript = JSON.parse(responseText)['transcript'];
            // Append at the cursor position
            const updatedText = [
                text.slice(0, cursorPosition),
                transcript,
                text.slice(cursorPosition)
            ].join('');

            setText(updatedText); // Update the text using setText
        }).catch(error => {
            console.error("Failed to send audio:", error);
            alert("Failed to send audio. See console for details.");
        });
    };

    const toggleRecording = () => {
        if (!recording) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;
                let audioChunks: BlobPart[] = [];

                mediaRecorder.ondataavailable = e => {
                    audioChunks.push(e.data);
                };

                mediaRecorder.start();

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, { 'type': 'audio/wav; codecs=opus' });
                    sendAudio(audioBlob);  // Automatically send after recording stops
                };

                setRecording(true);
            }).catch(error => {
                console.error("Error accessing media devices:", error);
            });
        } else {
            mediaRecorderRef.current && mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    const clearQuery = () => {
        setText('');
    };

    useEffect(() => {
        return () => {
            if (recording && mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
            }
        };
    }, [recording]);

	return (
        <div className="d-flex"> {/* Bootstrap class for display:flex */}
            <button
                onClick={clearQuery}
                className={`btn btn-sm ${text ? 'btn-secondary' : 'disabled'} me-1 mb-1`} // Using Bootstrap classes
                disabled={!text}
            >
                <i className="bi bi-trash"></i> {/* Bootstrap Icon for trash */}
            </button>

            <button
                onClick={toggleRecording}
                className={`btn btn-sm ${recording ? 'btn-danger' : 'btn-primary'} me-1 mb-1`} // Using Bootstrap classes for button
            >
                {recording ? <i className="bi bi-stop-fill"></i> : <i className="bi bi-mic-fill"></i>} {/* Bootstrap Icons for stop and mic */}
            </button>
        </div>
    );
}