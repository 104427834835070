
export const AVAILABLE_TOOLS = [
	{ value: 'math_tool', label: 'Math Tool' },
	{ value: 'fetch_start_and_end', label: 'Date/Time Tool' },
	{ value: 'get_word_length', label: 'Get Word Length' },
	// Yahoo Finance
	{ value: 'get_latest_market_info', label: 'YFinance: Latest Market Info' },
	{ value: 'get_latest_market_news', label: 'YFinance: Latest Market News' },
	// // Skrumify
	// { value: 'create_event', label: 'Skrumify Calendar Event' },
	// Notion
	{ value: 'notion_search_by_title', label: 'Notion: Search By Title' },
	{ value: 'notion_create_page', label: 'Notion: Create Page' },
	// Gitlab
	{ value: 'gitlab_create_branch', label: 'Gitlab: Create Branch' },
	{ value: 'gitlab_create_merge_request', label: 'Gitlab: Create Merge Request' },
	{ value: 'gitlab_create_commit', label: 'Gitlab: Create Commit' },
	{ value: 'gitlab_retrieve_files', label: 'Gitlab: Fetch Files' },
	{ value: 'gitlab_raw_blob_content', label: 'Gitlab: Fetch File Contents' },
	{ value: 'gitlab_new_issue', label: 'Gitlab: New Issue' },
	{ value: 'gitlab_edit_issue', label: 'Gitlab: Edit Issue' },
	{ value: 'gitlab_delete_issue', label: 'Gitlab: Delete Issue' },
	{ value: 'gitlab_list_issues', label: 'Gitlab: Search Issues' },
	{ value: 'gitlab_create_project', label: 'Gitlab: Create Project' },
]

export const BASIC_TOOLS = [
	{
		value: 'math_tool',
		label: 'Calculator',
		description: 'Perform basic math operations',
		alwaysEnabled: true,
	},
	// {
	// 	value: 'fetch_start_and_end',
	// 	label: 'Date/Time Tool',
	// 	description: 'Fetch the start and end of a time period',
	// },
	// {
	// 	value: 'get_word_length',
	// 	label: 'Get Word Length',
	// 	description: 'Get the length of a word',
	// },
]

export const OPENAI_TOOLS = [
	{
		value: 'generate_dalle_images',
		label: 'Generate DALL-E Images',
		description: 'Generate images using OpenAI\'s DALL-E model',
	},
]

export const ZABBIX_TOOLS = [
	{
		value: 'zabbix_get',
		label: 'Retrieve Zabbix Data',
		description: 'Perform a Zabbix API request. Available methods: host.get, hostgroup.get, item.get, trend.get, history.get, event.get, problem.get, hostinterface.get, trigger.get, graph.get, template.get, discoveryrule.get',
	}
]

export const PINECONE_TOOLS = [
	{
		value: 'pinecone_list_indexes',
		label: 'List Indexes',
		description: 'List all Pinecone indexes',

	},
	{
		value: 'pinecone_similarity_search',
		label: 'Similarity Search',
		description: 'Perform a similarity search on a Pinecone index',
	},
	{
		value: 'pinecone_index_info',
		label: 'Get Index Info',
		description: 'Get information about a Pinecone index',
	},
	{
		value: 'pinecone_rag',
		label: 'Retrieval Augmented Generation (RAG)',
		description: 'Generate text using Pinecone\'s retrieval augmented generation model',
	},
]