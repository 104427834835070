import { ChangeEvent, useState, useEffect } from 'react';
import { useAppContext } from "../../../contexts/AppContext";
import { useLoaderContext } from "../../../contexts/LoaderContext";
import { Document, Page, pdfjs } from 'react-pdf';
import marked from '../../../config/marked';

// Configure pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function FileForm() {
    const { loading, windowHeight } = useAppContext();
    const { handleFileChange, formData } = useLoaderContext();
    const [file, setFile] = useState<File | null>(null);
    const [fileType, setFileType] = useState('');
    const [previewContent, setPreviewContent] = useState('');
    const [numPages, setNumPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [scale, setScale] = useState(1.0);

    const getFileType = (fileName: string) => {
        const extension = fileName.split('.').pop();
        switch (extension) {
        case 'txt':
            return 'text/plain';
        case 'pdf':
            return 'application/pdf';
        case 'html':
            return 'text/html';
        case 'md':
            return 'text/markdown';
        default:
            return '';
        }
    };

    const onFileChange = (event: any) => {
        const files = event.target.files;
        handleFileChange(event); // Your existing file change handler

        if (files && files[0]) {
        const file = files[0];
        const inferredType = getFileType(file.name);
        setFileType(inferredType);
        setFile(file);
        const reader = new FileReader();

        reader.onload = (e) => {
            const content = e.target?.result as string;

            if (inferredType === 'application/pdf') {
            setPreviewContent('');
            } else {
            setPreviewContent(content);
            setNumPages(0);
            }
        };

        if (inferredType !== 'application/pdf') {
            reader.readAsText(file);
        }
        } else {
        setFile(null);
        setPreviewContent('');
        setFileType('');
        setNumPages(0);
        }
    };

    const renderPreview = () => {
        switch (fileType) {
            case 'text/plain':
                return <pre>{previewContent}</pre>;
            case 'text/html':
                return <div dangerouslySetInnerHTML={{ __html: previewContent }} />;
            case 'text/markdown':
                return <div style={{ overflowY: 'auto', maxHeight: '500px' }} dangerouslySetInnerHTML={{ __html: marked(previewContent) }} />;
            default:
                return <div>No preview available for this file type.</div>;
        }
    };

    const renderPDFPreview = () => (
        <div className="d-flex flex-column align-items-center">
            <div className="mb-2 d-flex justify-content-center align-items-center">
                <button onClick={goToPrevPage} disabled={currentPage <= 1} className="btn btn-primary btn-xs">
                    &lt;
                </button>
                <span className="mx-2">
                    Page {currentPage} of {numPages}
                </span>
                <button onClick={goToNextPage} disabled={currentPage >= numPages} className="btn btn-primary btn-xs">
                    &gt;
                </button>
                <select
                    onChange={(event) => setScale(Number(event.target.value))}
                    className="form-select form-select-sm mx-2"
                    value={scale}
                    style={{ width: "100px" }}
                >
                    <option value="0.5">50%</option>
                    <option value="0.75">75%</option>
                    <option value="1">100%</option>
                    <option value="1.25">125%</option>
                    <option value="1.5">150%</option>
                    <option value="2">200%</option>
                </select>
            </div>
            <div
                className="pdf-viewer overflow-auto"
                style={{ maxHeight: '500px', position: 'relative' }}
            >
                <Document
                    file={URL.createObjectURL(file || new Blob())}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page
                        pageNumber={currentPage}
                        scale={scale}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                    />
                </Document>
            </div>
        </div>
    );

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
        setCurrentPage(currentPage > 1 ? currentPage : 1); // Reset to first page when a new document is loaded
    };
    
    const goToPrevPage = () => {
        setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
    };
    
    const goToNextPage = () => {
        setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, numPages));
    };

    useEffect(() => {
        if (windowHeight < 600) {
            setScale(0.5);
        } else {
            setScale(1.0);
        }
    }, [windowHeight]);
    

    return (
        <form>
            <div className="mb-3">
                <label htmlFor="fileInput" className="form-label">Files</label>
                <input
                    id="fileInput"
                    className="form-control form-control-sm"
                    type="file"
                    disabled={loading}
                    onChange={onFileChange}
                    value={formData.files}
                    multiple
                />
                <div className="form-text">
                    Supported file types: .txt, .pdf, .html, .md, .csv
                </div>
            </div>
            {file && (
                <div className="mt-2 border rounded overflow-auto file-preview">
                    {fileType === 'application/pdf' && file ? renderPDFPreview() : renderPreview()}
                </div>
            )}
        </form>
    );
}