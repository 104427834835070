import { API_URL } from '../../config';
import { API } from './index';
import axios from 'axios';

export class SettingsClient extends API {

	public async index(token: string) {
        const options = this.getHeaders(token, 'bot');
        const res = await axios.get(
            `${API_URL}/api/v1/chat/settings`,
            options
        );
        return res.data;
    }

	public async create(token: string, payload: any) {
        try {
            const options = this.getHeaders(token, 'bot');
            const res = await axios.post(
                `${API_URL}/api/v1/chat/settings`,
                payload,
                options
            );
            return res;
        } catch (err) {
            console.log(err);
        }
    }

	public async show(token: string, id: string) {
        try {
            const options = this.getHeaders(token, 'bot');
            const res = await axios.get(
                `${API_URL}/api/v1/chat/settings/${id}`,
                options
            );
            console.log(res.data)
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

	public async update(token: string, id: string, payload: any) {
        try {
            const options = this.getHeaders(token, 'bot');
            const res = await axios.put(
                `${API_URL}/api/v1/chat/settings/${id}`,
                payload,
                options
            );
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

	public async delete(token: string, id: string) {
        try {
            const options = this.getHeaders(token, 'bot');
            const res = await axios.delete(
                `${API_URL}/api/v1/chat/settings/${id}`,
                options
            );
            return res;
        } catch (err) {
            console.log(err);
        }
    }
}