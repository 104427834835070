import { useState, useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import { SettingsClient } from '../../utils/api/settings';
import { useAuthContext } from '../../contexts/AuthContext';
import { useChatContext } from '../../contexts/ChatContext';

const settingsClient = new SettingsClient()

export default function NewAgentModal(props: { builder?: boolean }) {
	const { botAccessToken } = useAuthContext();
	const { loading, setLoading, setSidebarOpen } = useAppContext();
	const { setChatPayload, chatPayload, fetchAgents } = useChatContext();
	const [show, setShow] = useState(false);

	const handleOutsideClick = (e: MouseEvent) => {
        const modalContent = document.querySelector('.modal-content');
        if (show && modalContent && !modalContent.contains(e.target as Node)) {
            setShow(false);
        }
    };

	async function submitForm() {
		setLoading(true);
		// If not uploading a file directly add here.
		try {

			const payload = {...chatPayload, system: chatPayload.systemMessage}
			delete chatPayload._id;
			delete chatPayload.query;
			delete chatPayload.setting;
			delete chatPayload.systemMessage;
			const res = await settingsClient.create(botAccessToken, payload);
			if (res && res.status === 200) {
				await fetchAgents();
				setChatPayload({
					...chatPayload,
					setting: ''
				})
				setShow(!show);
				setSidebarOpen(false);
			}
		} catch (error) {
			console.log(error);
			alert('Error creating agent, check console for more details')
		}
		setLoading(false);
	}

	useEffect(() => {
        // Add when the component mounts
        document.addEventListener("mousedown", handleOutsideClick);

        // Remove when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [show]);

	return (
		<>
			<button
					type="button"
					className="btn btn-outline-light"
					style={{ width: '100%', color: "#BEC9D9" }}
					onClick={(e) => {
						e.stopPropagation();
						setShow(!show);
					}}
				>
					<i className="bi bi-robot"></i> New Agent
				</button>
			<div
				className={"modal fade" + (show ? " modal-backdrop-show show" : "")}
				tabIndex={-1}
				role="dialog"
				style={{ display: show ? 'block' : 'none', zIndex: 35 }}
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content" style={{zIndex: 35}}>
						{/* <form onSubmit={handleSubmit}> */}
							<div className="modal-body">
								<div className="row">
									<div className="col">
										<div className="mb-3">
											<label className="form-label">Agent Name</label>
											<input
												onChange={(e) => setChatPayload({
													...chatPayload,
													title: e.target.value
												})}
												type="text"
												className="form-control"
												placeholder="Enter name of agent"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-bs-dismiss="modal"
									onClick={() => setShow(!show)}
								>
									Close
								</button>
								<button
									type="submit"
									className="btn btn-primary"
									onClick={submitForm}
									disabled={loading}
								>
									Create Agent
								</button>
							</div>
						{/* </form> */}
					</div>
				</div>
			</div>
		</>
	);
}