
export enum OpenAiChatModels {
    GPT_3_5_TURBO = 'gpt-3.5-turbo',
    GPT_3_5_TURBO_16K = 'gpt-3.5-turbo-1106',
    GPT_3_5_TURBO_MARCH = 'gpt-3.5-turbo-0301',
    GPT_4 = 'gpt-4',
    GPT_4_MARCH = 'gpt-4-0314',
    GPT_4_32K = 'gpt-4-32k',
    GPT_4_32K_MARCH = 'gpt-4-32k-0314',
    GPT_4_TURBO = 'gpt-4-0125-preview',
    GPT_4_VISION = 'gpt-4-vision-preview'
}

const SYSTEM_MESSAGE_CHATGPT = `Assistant is a large language model trained by OpenAI. Assistant is designed to be able `
                                +`to assist with a wide range of tasks, from answering simple questions to providing in-depth `
                                +`explanations and discussions on a wide range of topics. As a language model, Assistant is able `
                                +`to generate human-like text based on the input it receives, allowing it to engage in natural-sounding `
                                +`conversations and provide responses that are coherent and relevant to the topic at hand. `
                                +`Assistant is constantly learning and improving, and its capabilities are constantly evolving. `
                                +`It is able to process and understand large amounts of text, and can use this knowledge to provide `
                                +`accurate and informative responses to a wide range of questions. Additionally, Assistant is able to `
                                +`generate its own text based on the input it receives, allowing it to engage in discussions and provide `
                                +`explanations and descriptions on a wide range of topics. Overall, Assistant is a powerful tool that can `
                                +`help with a wide range of tasks and provide valuable insights and information on a wide range of topics. `
                                +`Whether you need help with a specific question or just want to have a conversation about a particular `
                                +`topic, Assistant is here to assist.`;

const SYSTEM_MESSAGE_RETRIEVAL = `Retrieve and synthesize accurate information from relevant sources to `
                                + `answer the following query. Ensure the response is based on the data retrieved `
                                + `and does not include hallucinated or speculative content. Provide a coherent and `
                                + `concise answer that integrates key points from different sources if necessary for accuracy and completeness.`

export const Defaults = {
    SYSTEM_MESSAGE_CHATGPT: SYSTEM_MESSAGE_CHATGPT,
    SYSTEM_MESSAGE_CONTEXTGPT: SYSTEM_MESSAGE_RETRIEVAL,
    SYSTEM_MESSAGE: "You are a powerful AI assistant"
}
