import Select from "../selects/Select";
import { filterByValue } from "../../utils/filter";
import { capitalizeFirstLetter } from "../../utils/format";
import { MODEL_OPTIONS, VECTOR_DB_OPTIONS } from "../../config/options";
import { useLoaderContext } from "../../contexts/LoaderContext";
import { useState } from "react";
import ToolModal from "../modals/ToolModal";
import IndexModal from "../modals/IndexModal";
import AudioRecorder from "../files/AudioRecorder";
import { useChatContext } from "../../contexts/ChatContext";

const selectStyle = {
    control: (base: any, state: any) => ({
        ...base,
        backgroundColor: '#334257',
        color: '#BEC9D9',
        border: 'none',  // Removes the border
        boxShadow: state.isFocused ? 0 : base.boxShadow, // Removes the default focus shadow
        '&:hover': {
            border: 'none', // Removes border on hover
        }
    }),
    container: (base: any) => ({
        ...base,
        width: '100%',
    }),
    menu: (base: any) => ({
        ...base,
        backgroundColor: '#334257',
        width: 'calc(100% - 20px)',
    }),
    singleValue: (base: any) => ({
        ...base,
        color: '#BEC9D9',
    }),
    option: (base: any, state: any) => ({
        ...base,
        backgroundColor: state.isSelected ? '#5A6981' : '#334257',
        color: '#BEC9D9',
        ':hover': {
            backgroundColor: state.isSelected ? '#5A6981' : '#475569',
        },
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: '#BEC9D9'
    }),
    clearIndicator: (base: any) => ({
        ...base,
        color: '#BEC9D9'
    }),
};

const SettingsMenu = ({ chatPayload, setChatPayload, type }: any) => {
	const {listVectorIndexes, vectorstores, deleteVectorstore} = useLoaderContext();
	const [loading, setLoading] = useState(false);
	const { cursorPosition } = useChatContext();

	const updateSystemMessage = (newMessage: string) => {
        setChatPayload({ ...chatPayload, systemMessage: newMessage });
    };


    return (
        <div className="sidebar-menu mt-3" style={{ height: 'calc(100% - 150px)', overflowY: 'auto' }}>
            <div className="sidebarMenuScroll os-host os-theme-dark os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
                <div className="os-resize-observer-host observed">
                    <div className="os-resize-observer" style={{ left: '0px', right: 'auto' }}></div>
                </div>
                <div className="os-padding">
                    <div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}>
                        <div className="os-content" style={{ padding: '0px', height: '100%', width: '100%' }}>
                            <ul>
								{type !== 'default' && (
									<li
										style={{
											display: 'flex',      // Make it a flex container
											alignItems: 'center', // Vertically center the child
											justifyContent: 'center', // Horizontally center the child
											height: '100%',       // Optional, if you want the <li> to fill the container
											width: '100%'         // Optional, if you want the <li> to fill the container
										}}
									>
										<div className="input-group px-3 mb-1">
											<label className="form-label" style={{ color: "#BEC9D9" }}>Vector Index</label>
											<Select
                                                cleared
												styles={selectStyle}
												handleChange={(item: any) => {
                                                    if (item) {
                                                        setChatPayload({ ...chatPayload, retrieval: {...chatPayload.retrieval, index_name: item.value} });
                                                        sessionStorage.setItem('vectorstore', item.value);
                                                    } else {
                                                        setChatPayload({ ...chatPayload, retrieval: {...chatPayload.retrieval, index_name: ''} });
                                                        sessionStorage.removeItem('vectorstore');
                                                    }
                                                }}
												loading={loading}
												value={
                                                    chatPayload.retrieval?.index_name
                                                    ? {label: chatPayload.retrieval?.index_name, value: chatPayload.retrieval?.index_name}
                                                    : null
                                                }
												deleteCallback={async (item: {label: string, value: string}) => {
													try {
														await deleteVectorstore({
															provider: chatPayload.retrieval.provider,
															vectorstore: item.value
														})
														alert(`Vectorstore ${item.label} deleted successfully.`)
													} catch (error: unknown) {
														// Handle error here
														console.error(error);
														alert((error as any).response.data.detail);
													}
												}}
												options={vectorstores}
												onMenuOpen={async () => {
													if (vectorstores.length < 1) {
														setLoading(true);
													}
													await listVectorIndexes(chatPayload.retrieval.provider);
													setLoading(false);
												}}
											/>
										</div>
									</li>
								)}
                                {type !== 'default' && (
                                    <li
                                        style={{
                                            display: 'flex',      // Make it a flex container
                                            alignItems: 'center', // Vertically center the child
                                            justifyContent: 'center', // Horizontally center the child
                                            height: '100%',       // Optional, if you want the <li> to fill the container
                                            width: '100%'         // Optional, if you want the <li> to fill the container
                                        }}
                                    >
                                        <div className="input-group px-3 mb-1">
                                            <label className="form-label" style={{ color: "#BEC9D9" }}>Vector Database Provider</label>
                                            <Select
                                                handleChange={(e: any) => {
													console.log(e)
													setChatPayload({...chatPayload, retrieval: {...chatPayload.retrieval, provider: e.value}})
												}}
                                                value={chatPayload.retrieval.provider ? filterByValue(VECTOR_DB_OPTIONS, 'value', chatPayload.retrieval.provider)[0] : VECTOR_DB_OPTIONS[0]}
                                                options={VECTOR_DB_OPTIONS}
                                                styles={selectStyle}
                                            />
                                        </div>
                                    </li>
                                )}
                                <li
                                    style={{
                                        display: 'flex',      // Make it a flex container
                                        alignItems: 'center', // Vertically center the child
                                        justifyContent: 'center', // Horizontally center the child
                                        height: '100%',       // Optional, if you want the <li> to fill the container
                                        width: '100%'         // Optional, if you want the <li> to fill the container
                                    }}
                                >
                                    <div className="input-group px-3">
                                        <label className="form-label" style={{ color: "#BEC9D9" }}>Model</label>
                                        <Select
                                            handleChange={(e: any) => setChatPayload({...chatPayload, model: e.value})}
                                            value={filterByValue(MODEL_OPTIONS, 'value', chatPayload.model)[0] || MODEL_OPTIONS[0]}
                                            options={MODEL_OPTIONS}
                                            styles={selectStyle}
                                        />
                                    </div>
                                </li>
                                <li
                                    style={{
                                        display: 'flex',      // Make it a flex container
                                        alignItems: 'center', // Vertically center the child
                                        justifyContent: 'center', // Horizontally center the child
                                        height: '100%',       // Optional, if you want the <li> to fill the container
                                        width: '100%'         // Optional, if you want the <li> to fill the container
                                    }}
                                >
                                    <div className="input-group px-3 mt-2">
                                        <div className="container-fluid">
                                            <div className="row mb-2">
                                                <div className="col px-0">
                                                    <label className="form-label" style={{ color: "#BEC9D9" }}>Temperature</label>
                                                </div>
                                                <div className="col" style={{ position: 'relative' }}>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        value={chatPayload.temperature || 0.5}
                                                        disabled
                                                        style={{
                                                            width: '40px',
                                                            height: '10px',
                                                            position: 'absolute',
                                                            right: 0,
                                                            backgroundColor: "#334257",
                                                            color: "#BEC9D9"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            type="range"
                                            className="form-range"
                                            min="0"
                                            max="1"
                                            value={chatPayload.temperature || 0.5}
                                            step="0.1"
                                            onChange={(e) => setChatPayload({...chatPayload, temperature: e.target.value})}
                                            id="customRange2"
                                        />
                                    </div>
                                </li>
                                <li
                                    style={{
                                        display: 'flex',      // Make it a flex container
                                        alignItems: 'start', // Vertically center the child
                                        justifyContent: 'start', // Horizontally center the child
                                        height: '100%',       // Optional, if you want the <li> to fill the container
                                        width: '100%'         // Optional, if you want the <li> to fill the container
                                    }}
                                >
                                    <textarea
                                        className="form-control mx-3 mt-2"
                                        rows={8.5}
                                        onChange={(e) => setChatPayload({...chatPayload, systemMessage: e.target.value})}
                                        value={chatPayload.systemMessage || ''}
                                        style={{ backgroundColor: "#334257", color: "#BEC9D9" }}
                                    ></textarea>
                                </li>
								<div className="sidebar-custom-nav">
									<AudioRecorder
										text={chatPayload.systemMessage}
										setText={updateSystemMessage}
										cursorPosition={cursorPosition}
									/>
									{type !== 'default' && (
										<>
											<ToolModal />
											<IndexModal builder={true} />
										</>
									)}
								</div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsMenu;