import { useState, useEffect } from "react";
import { Setting } from "../../types/agent";
import { truncate } from "../../utils/format";
import { useNavigate } from "react-router-dom";
import { useChatContext } from "../../contexts/ChatContext";

export default function AgentCard({ setting }: { setting: Setting }) {
    let navigate = useNavigate();
    const {removeAgent} = useChatContext();
    function avatarString(id: string) {
        return `https://api.dicebear.com/5.x/bottts-neutral/svg?seed=${id}&backgroundColor=1553A3`;
    }

    const [remove, setRemove] = useState({
        id: '',
        submit: false,
    });

    // Convert Unix timestamp to readable date
    function formatDate(timestamp: number) {
        return new Date(timestamp * 1000).toLocaleDateString("en-US");
    }

    useEffect(() => {
		if(remove.submit) {
			removeAgent(remove.id)
		}
	}, [remove.submit])

    return (
        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={setting._id}>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div className="card-title">
                        <h1>{setting.title}</h1>
                    </div>
                    <img
                        src={avatarString(setting._id)}
                        className="rounded-circle img-fluid img-5x"
                        alt={setting.title}
                        style={{ maxWidth: '60px', maxHeight: '60px' }}
                    />
                </div>
                <div className="card-body" style={{ minHeight: '200px' }}>
                    <p className="mb-3 text-muted" style={{ fontSize: '0.85rem' }}>
                        {truncate(setting.system, 150)}
                    </p>
                    <div className="row">
                        <div className="col">
                            <p className="mb-1">Temperature: <strong>{setting.temperature}</strong></p>
                            <p className="mb-1">Model: <strong>{truncate(setting.model, 15)}</strong></p>
                        </div>
                        <div className="col">
                            <p className="mb-1">Provider: <strong>{setting.retrieval.index_name && setting.retrieval.provider}</strong></p>
                            <p className="mb-1">Index: <strong>{setting.retrieval.index_name}</strong></p>
                        </div>
                    </div>
                    {/* Modified container for badges with custom style to hide scrollbar */}
                    <div className="row">
                        <div className="col pe-0">
                            <div className="d-flex flex-row flex-nowrap overflow-auto hide-scrollbar">
                                {setting.tools.map((tool, idx) => (
                                    <span key={idx} className="badge rounded-pill bg-primary me-1">{tool}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-between" style={{ position: 'relative' }}>
                    <span>Last updated: {formatDate(setting.updated_at)}</span>
                    <div className="button-group">
                        {remove.id ? (
                            <div className="d-flex" style={{ position: 'absolute', bottom: 4, right: 10 }}>
                                {/* Convert to Bootstrap icons or custom components */}
                                <button
                                    className={`btn px-0`}
                                    style={{ color: 'red' }}
                                    onClick={() => setRemove({ id: '', submit: false })}
                                >
                                    <i className="bi bi-x-circle" style={{ fontSize: '20px' }}></i>
                                </button>
                                <button
                                    className={`btn px-0`}
                                    style={{ color: '#2C998A' }}
                                    onClick={() => setRemove({ ...remove, submit: true })}
                                >
                                    <i className="bi bi-check-circle-fill" style={{ fontSize: '20px' }}></i>
                                </button>
                            </div>
                        ) : (
                            <div className="d-flex" style={{ position: 'absolute', bottom: 4, right: 10 }}> {/* Bootstrap class for display:flex */}
                                <button
                                    className={`btn px-1`}
                                    onClick={() => navigate('/agents/' + setting._id)}
                                >
                                    <i className="bi bi-chat-right" style={{ fontSize: '20px' }}></i> {/* Bootstrap Icon for trash */}
                                </button>
                                <button
                                    className={`btn px-1`}
                                    onClick={() => alert('Editing...')}
                                >
                                    <i className="bi bi-pencil-fill" style={{ fontSize: '20px' }}></i> {/* Bootstrap Icon for trash */}
                                </button>
                                <button
                                    className={`btn px-1`}
                                    onClick={() => setRemove({ id: setting._id, submit: false })}
                                >
                                    <i className="bi bi-trash" style={{ fontSize: '20px' }}></i> {/* Bootstrap Icon for trash */}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
