import { useEffect, useState } from "react";
import { useChatContext } from "../../contexts/ChatContext";
import { truncate } from "../../utils/format";

const AgentMenu = ({ chatPayload }: any) => {
	const { fetchAgents, agents, selectAgent, removeAgent, setActiveTab } = useChatContext();
	const [remove, setRemove] = useState({
		id: '',
		submit: false,
	});

    useEffect(() => {
		if(remove.submit) {
			removeAgent(remove.id)
		}
	}, [remove.submit])

	useEffect(() => {
		(async () => {
			await fetchAgents();
		})();
	}, []);

    return (
		<div className="sidebar-menu" style={{ height: 'calc(100% - 115px)', overflowY: 'auto' }}>
            <div className="sidebarMenuScroll os-host os-theme-dark os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
                <div className="os-resize-observer-host observed">
                    <div className="os-resize-observer" style={{ left: '0px', right: 'auto' }}></div>
                </div>
                <div className="os-padding">
                    <div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}>
                        <div className="os-content" style={{ padding: '0px', height: '100%', width: '100%' }}>
                            <ul>
                                {agents.map((item: any, index: number) => (
                                    <li
                                        className={item._id === chatPayload.setting ? 'active-page-link' : ''}
                                        key={index}
                                        style={{ display: 'flex', alignItems: 'center', position: 'relative', margin: 0}}
										onClick={() => selectAgent(item)}
                                    >
                                        <a
											href="#"
											onClick={(event) => event.preventDefault()}
											style={{ padding: '5px', width: "100%" }}
										>
                                            <i
												className="bi bi-robot"
												style={{ marginRight: '5px', fontSize: '20px' }}
											></i>
                                            <div style={{ flex: 1 }}>
                                                <span
													className="menu-text"
													style={{
														fontSize: '14px',
														color: '#F8F8F8',
														display: 'block',
														marginBottom: '3px',
														marginTop: '10px'
													}}
												>
                                                    {truncate(item.title, 32)}
                                                </span>
                                                <span style={{ display: 'block', fontSize: '10px' }}>
													{new Intl.DateTimeFormat('default', {
														year: 'numeric',
														month: 'short',
														day: 'numeric',
														hour: '2-digit',
														minute: '2-digit',
														hour12: true
													}).format(new Date(item.updated_at * 1000)) || 'No date'} {/* Formats the current date */}
                                                </span>
                                            </div>
                                        </a>
										{chatPayload.setting === item._id && (
                                            <>
                                                {remove && remove.id === item._id ? (
                                                    <>
                                                        {/* Convert to Bootstrap icons or custom components */}
                                                        <span
                                                            className="position-absolute"
                                                            style={{ right: 45, bottom: 2, color: 'red' }}
                                                            onClick={()=>setRemove({id: '', submit: false})}
                                                        >
                                                            <i className="bi bi-x-circle"></i>
                                                        </span>
                                                        <span
                                                            className="position-absolute"
                                                            style={{ right: 25, bottom: 3, color: '#2C998A' }}
                                                            onClick={()=>setRemove({...remove, submit: true})}
                                                        >
                                                            <i className="bi bi-check-circle-fill"></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* Convert to Bootstrap icons or custom components */}
                                                        <span
                                                            className="position-absolute"
                                                            style={{ right: 45, bottom: 2, color: 'white' }}
                                                            onClick={()=>{
																selectAgent(item)
																setActiveTab('settings')
															}}
                                                        >
                                                            <i className="bi bi-pencil-square"></i>
                                                        </span>
                                                        <span
                                                            className="position-absolute"
                                                            style={{ right: 25, bottom: 3, color: 'white' }}
                                                            onClick={()=>setRemove({id: item._id, submit: false})}
                                                        >
                                                            <i className="bi bi-trash3"></i>
                                                        </span>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentMenu;