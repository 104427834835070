import '../../App.css'
import { useEffect } from 'react';
import AgentCard from '../../components/cards/AgentCard';
import { useChatContext } from '../../contexts/ChatContext';
import MainLayout from '../../layouts/MainLayout';
import { useAuthContext } from '../../contexts/AuthContext';


export default function Agents() {
    const {botAccessToken} = useAuthContext();
    const {agents, fetchAgents} = useChatContext();
    useEffect(() => {
        const retreiveAgents = async () => {
            try {
                await fetchAgents();
            } catch (e: any) {
                console.error(e);
            }
        }
		if (botAccessToken) retreiveAgents();
	}, []);
    return (
        <MainLayout>
            <div className='chat-container' style={{ position: 'relative', overflow: 'hidden' }}>
                <div className="container-fluid pt-3">
                    <div className="row scrollable-grid">
                        {agents.map((setting: any) => (
                            <AgentCard
                                setting={setting}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}
