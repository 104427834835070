// Desc: Login page for the portal
import { API_URL } from '../config';

export default function Login() {

	const handleSubmit = async (e: any) => {
        window.location.href = `${API_URL}/login`
    };

	return (
        <div className={'login-container'} style={{ height: '100vh' }}>
			<div className="container">
				<div className="login-box rounded-2 p-5">
					<div className="login-form">
						<center>
							<img src="https://pxtportal.com/img/logo.png" alt="PrecisionX Technology Logo" />
							<h5 className="fw-light mt-4"><strong>PrecisionX Technology Portal</strong></h5>
						</center>
						<hr />
						<div className="d-grid py-3">
							<button type="button" className="btn btn-lg btn-primary" onClick={handleSubmit}>
								Login
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
}