import { useRef } from "react";
import { API_URL } from "../../config";
import { useAuthContext } from "../../contexts/AuthContext";
import { useLoaderContext } from "../../contexts/LoaderContext";

const FilesMenu = () => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { botAccessToken } = useAuthContext();
    const { files, setFiles } = useLoaderContext();

    // Event handler for file input change
    const handleFileChange = (event: any) => {
        setFiles([...event.target.files]);
    };

    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input
        }
    };

    const handleSubmit = async () => {
        if (!files || files.length === 0) {
            alert('No files to upload');
            return;
        }

        try {
            const formData = new FormData();
            files.forEach((file: File) => formData.append('files', file, file.name));

            const response = await fetch(`${API_URL}/api/v1/files`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Basic ${botAccessToken}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('Files uploaded successfully', responseData);

            // Reset the file input after successful upload
            resetFileInput();
            setFiles([]);
        } catch (error) {
            console.error('Error uploading files', error);
        }
    };

    return (
        <div className="sidebar-menu">
            <div className="sidebarMenuScroll os-host os-theme-dark os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
                <div className="os-resize-observer-host observed">
                    <div className="os-resize-observer" style={{ left: '0px', right: 'auto' }}></div>
                </div>
                <div className="os-padding">
                    <div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}>
                        <div className="os-content" style={{ padding: '0px', height: '100%', width: '100%' }}>
                            <ul>
                                <li>
                                    <div className="px-3">
                                        <label className="form-label" style={{ color: "#BEC9D9" }}>Upload Files</label>
                                        <div className="input-group input-group-sm">
                                            <input
                                                ref={fileInputRef} // Attach the ref to the input
                                                type="file"
                                                className="form-control form-control-sm"
                                                aria-describedby="Upload Files"
                                                aria-label="Upload"
                                                multiple={true}
                                                onChange={handleFileChange}
                                            />
											<button
                                                className="btn btn-outline-success"
                                                type="button"
                                                onClick={handleSubmit}
                                            >
                                                <i className="bi bi-file-check"></i>
											</button>
										</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilesMenu;