import { useContext, createContext, useState, useRef, useEffect } from "react";
import { IContextProvider } from "../interfaces/Provider";
import { useAuthContext } from "./AuthContext";
import { ChatClient } from "../utils/api/chat";
import { Defaults, OpenAiChatModels } from "../config/openai";
import { useLoaderContext } from "./LoaderContext";
import { filterChatHistory } from "../utils/chat";
import { ON_PREM } from "../config";
import { removeObjectById } from "../utils/format";
import { SettingsClient } from "../utils/api/settings";
// import { filterChatHistory } from "../utils/chat";

export const ChatContext = createContext({});

const chatClient = new ChatClient();
const settingsClient = new SettingsClient();

export default function ChatProvider({ children }: IContextProvider) {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const chatboxRef = useRef<HTMLTextAreaElement>(null);
	const [chatboxRefIsEmpty, setChatboxRefIsEmpty] = useState(true);
	const [cursorPosition, setCursorPosition] = useState<number>(0);
	const [activeTab, setActiveTab] = useState('history');

    // Contexts
    const { botAccessToken } = useAuthContext();
    const { params, setParams, loaderForm, vectorstores, setLoadersPayload, loadersPayload } = useLoaderContext();

    // State
	const [header, setHeader] = useState('');
	const [isChecked, setIsChecked] = useState(false);
    const [histories, setHistories] = useState<any>([]);
	const [agents, setAgents] = useState<any>([]);
    const [connected, setConnected] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [doneTyping, setDoneTyping] = useState(0);
    const [type, setType] = useState(() => {
		const savedType = sessionStorage.getItem('chatType');
		return savedType ?? 'default'; // Replace 'defaultType' with your default
	});
    const [systemMessage, setSystemMessage] = useState(() => {
		const savedSystem = sessionStorage.getItem('systemMessage');
		return savedSystem ?? Defaults.SYSTEM_MESSAGE_CHATGPT; // Replace 'defaultType' with your default
	});
    const [messages, setMessages] = useState([
        {role: 'system', content: ''},
    ]);
    const [tools, setTools] = useState([]);
    const [chatPayload, setChatPayload] = useState(() => {
        const savedModel = sessionStorage.getItem('model');
		const savedTemp = sessionStorage.getItem('temperature') ? parseFloat(sessionStorage.getItem('temperature') || '') : 0.9;
		const savedSystem = sessionStorage.getItem('systemMessage');
		const savedVectorstore = sessionStorage.getItem('vectorstore');
		const savedProvider = sessionStorage.getItem('provider');
        return {
            _id: '',
            query: '',
			systemMessage: savedSystem ?? Defaults.SYSTEM_MESSAGE_CHATGPT,
            temperature: savedTemp ?? 0.5,
            model: savedModel ?? (ON_PREM ? 'llama2' : OpenAiChatModels.GPT_3_5_TURBO_16K), // Use savedModel if available
			retrieval: {
				provider: savedProvider ?? (ON_PREM ? 'redis' : 'pinecone'),
				index_name: savedVectorstore ?? (vectorstores[0]?.value || '')
			},
            functions: [],
            tools: [],
			plugins: [],
			title: '',
			setting: '',
        };
    });

    async function fetchHistory() {
        try {
            const res = await chatClient.history(
                botAccessToken,
                loaderForm
            );
            console.log(res.data)
            return res
        } catch (error) {
            // Handle error here
            console.error(error);
            alert((error as any).response.data.detail)
        }
    }

    function resetChat() {
        setIsConfirmed(true);
		setChatboxRefIsEmpty(true);
        while (chatboxRef.current?.firstChild) {
            chatboxRef.current.removeChild(chatboxRef.current.firstChild);
        }
        setMessages([
            {role: 'system', content: ''},
        ]);
        setChatPayload({
            ...chatPayload,
            _id: '',
            // vectorstore: '',
            functions: [],
        });
        setTools([]);
        setTimeout((function() {
            console.log("This will be logged after 2 seconds");
            setIsConfirmed(false);
        }), 2000);
    }

    async function updateHistories(setting_id: string | undefined) {
        const res = await chatClient.fetchHistoryList(botAccessToken, setting_id);
        const histories = filterChatHistory(res.histories)
        setHistories(histories);
    }

    async function handleRemove(id: string) {
		const chatClient = new ChatClient();
		const res = await chatClient.deleteChatHistory(botAccessToken, id);
		if (res.status === 204) {
			setHistories(removeObjectById(histories, id));
			while (chatboxRef.current?.firstChild) {
				if(chatboxRef.current?.contains(chatboxRef.current.firstChild)) {
					chatboxRef.current.removeChild(chatboxRef.current.firstChild);
				}
			}
			setChatPayload({ ...chatPayload, _id: '' });
			setMessages([
				{role: 'system', content: ''},
			])
		};
	}

	async function fetchAgents() {
        const data = await settingsClient.index(botAccessToken);
        setAgents(data.settings);
    }

	function selectAgent(agent: any) {
		setChatPayload({
			...chatPayload,
			systemMessage: agent.system,
			temperature: agent.temperature,
			model: agent.model,
			retrieval: agent.retrieval,
			tools: agent.tools,
			plugins: agent.plugins,
			setting: agent._id,
		});
	}

	async function removeAgent(id: string) {
		const res = await settingsClient.delete(botAccessToken, id);
		if (res && res.status === 204) {
			setAgents(removeObjectById(agents, id));
			setChatPayload({ ...chatPayload, setting: '' });
		};
	}

	useEffect(() => {
		if (messages.length > 1) {
			setChatboxRefIsEmpty(false);
		} else {
			setChatboxRefIsEmpty(true);
		}
	}, [messages]);

	useEffect(() => {
		// Save type to sessionStorage whenever it changes
		sessionStorage.setItem('chatType', type);
	}, [type]);

	useEffect(() => {
		// Save type to sessionStorage whenever it changes
		sessionStorage.setItem('systemMessage', chatPayload.systemMessage);
	}, [chatPayload.systemMessage]);

	useEffect(() => {
		// Save type to sessionStorage whenever it changes
		sessionStorage.setItem('model', chatPayload.model);
	}, [chatPayload.model]);

	useEffect(() => {
		// Save type to sessionStorage whenever it changes
		sessionStorage.setItem('temperature', (chatPayload.temperature as any));
	}, [chatPayload.temperature]);

	useEffect(() => {
		// Save type to sessionStorage whenever it changes
		sessionStorage.setItem('vectorstore', (chatPayload.retrieval?.index_name as string));
	}, [chatPayload.retrieval?.index_name]);

	useEffect(() => {
		// Save type to sessionStorage whenever it changes
		sessionStorage.setItem('provider', (chatPayload.retrieval?.provider as string));
        setLoadersPayload({ ...loadersPayload, provider: chatPayload.retrieval?.provider  });
	}, [chatPayload.retrieval?.provider]);

    useEffect(() => {
		(async () => {
            const settingData = await settingsClient.show(botAccessToken, chatPayload.setting);
            if (settingData) {
                setChatPayload({
                    ...chatPayload,
                    systemMessage: settingData.setting?.system || sessionStorage.getItem('systemMessage') || Defaults.SYSTEM_MESSAGE_CHATGPT,
                    temperature: settingData.setting?.temperature || 0.9,
                    model: settingData.setting?.model || OpenAiChatModels.GPT_3_5_TURBO_16K,
                    retrieval: settingData.setting?.retrieval || 'pinecone',
                    tools: settingData.setting?.tools || [],
                    plugins: settingData.setting?.plugins || [],
                })
            }
        })()
	}, [chatPayload.setting]);

    return (
        <ChatContext.Provider
            value={{
                updateHistories,
                params,
                setParams,
                fetchHistory,
                header,
                setHeader,
                messages,
                setMessages,
                connected,
                setConnected,
                isChecked,
                setIsChecked,
                inputRef,
                type,
                setType,
                doneTyping,
                setDoneTyping,
                chatPayload,
                setChatPayload,
                histories,
                setHistories,
                systemMessage,
                setSystemMessage,
                resetChat,
                isConfirmed,
                setIsConfirmed,
                tools,
                setTools,
                chatboxRef,
				chatboxRefIsEmpty,
				setChatboxRefIsEmpty,
                handleRemove,
				fetchAgents,
				agents,
				setAgents,
				selectAgent,
				activeTab,
				setActiveTab,
				removeAgent,
				cursorPosition,
				setCursorPosition,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
}

export function useChatContext(): any {
	return useContext(ChatContext);
}