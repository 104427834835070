import { useEffect } from "react";
import { useChatContext } from "../../../contexts/ChatContext";
import { constructAssistantMessageDiv, constructUserMessageDiv } from "../../../utils/chat";
import marked from "../../../config/marked";
import HistoryMenu from "../../menus/HistoryMenu";
import FilesMenu from "../../menus/FilesMenu";
import SettingsMenu from "../../menus/SettingsMenu";
import AgentMenu from "../../menus/AgentMenu";
import { useAuthContext } from "../../../contexts/AuthContext";
import { SettingsClient } from "../../../utils/api/settings";
import NewAgentModal from "../../modals/NewAgentModal";
import { useLocation, useParams } from 'react-router-dom';

const settingClient = new SettingsClient();

export default function Sidebar() {
    let { agentId } = useParams();
    let location = useLocation();
	const { botAccessToken } = useAuthContext();
    const {
        updateHistories,
        type,
        histories,
        chatPayload,
        chatboxRef,
        setChatPayload,
        setMessages,
        resetChat,
		activeTab,
		setActiveTab,
        setType
    } = useChatContext();

    const handleTabChange = (tabName: string) => {
        setActiveTab(tabName);
    };

    const isActive = (tabName: string) => {
        return activeTab === tabName ? 'active-tab' : '';
    };

    async function historySelection(item: any) {
        console.log('Conversation selected: ', item);
        const settingData = item.setting ? await settingClient.show(botAccessToken, item.setting) : null;
        const payload = {
            ...chatPayload,
            _id: item._id,
            model: settingData?.setting.model,
            temperature: settingData?.setting.temperature,
            tools: settingData?.setting.tools,
            systemMessage: settingData?.setting.system,
            retrieval: settingData?.setting.retrieval,
            setting: settingData?.setting._id,
        }
        setChatPayload(payload);
        if (payload.system) {
            sessionStorage.setItem('systemMessage', payload.system);
        } else {
            sessionStorage.removeItem('systemMessage');
        }

        if (payload.model) {
            sessionStorage.setItem('model', payload.model);
        } else {
            sessionStorage.removeItem('model');
        }

        if (payload.temperature) {
            sessionStorage.setItem('temperature', JSON.stringify(payload.temperature));
        } else {
            sessionStorage.removeItem('temperature');
        }

        if (payload.retrieval && payload.retrieval.index_name) {
            sessionStorage.setItem('vectorstore', payload.retrieval.index_name);
        } else {
            sessionStorage.removeItem('vectorstore');
        }

        if (payload.retrieval && payload.retrieval.provider) {
            sessionStorage.setItem('provider', payload.retrieval.provider);
        } else {
            sessionStorage.removeItem('provider');
        }
        setMessages(item.messages);
        setTimeout(() => {
            while (chatboxRef.current?.firstChild) {
                chatboxRef.current.removeChild(chatboxRef.current.firstChild);
            }
            for (let i = 0; i < item.messages.length; i++) {
                if (item.messages[i].role === 'user') {
                    let userMessageDiv = constructUserMessageDiv([item.messages[i]]);
                    chatboxRef.current?.appendChild(userMessageDiv);
                }
                if (item.messages[i].role === 'assistant') {
                    let assistantMessageDiv = constructAssistantMessageDiv();
                    assistantMessageDiv.innerHTML = marked.parse(item.messages[i].content);
                    chatboxRef.current?.appendChild(assistantMessageDiv);
                }
            }
        }, 500);
    }

	function test() {
		alert('New Agent!')
		return;
	}

    useEffect(() => {
        const retrieveHistory = async () => {
            try {
                agentId ? await updateHistories(agentId) : await updateHistories();
            } catch (e: any) {
                console.error(e);
            }
        }
        if (botAccessToken) retrieveHistory();
	}, [botAccessToken]);

    useEffect(() => {
        if (agentId) {
            setChatPayload((prev: any) => ({ ...prev, setting: agentId }));
        }
    }, [agentId])

    useEffect(() => {
        if (location.pathname === '/agents') {
            setType('agent');
            setActiveTab('settings');
        }
    }, [])

    return (
        <nav className="sidebar-wrapper">
            {location.pathname !== '/agents' ? (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col px-4 mt-3">
                                 <button
                                    type="button"
                                    className="btn btn-outline-light"
                                    style={{ width: '100%', color: "#BEC9D9" }}
                                    onClick={resetChat}
                                >
                                    <i className="bi bi-plus-lg"></i>New Chat
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-custom-nav">
                        <a href="#" className={isActive('history')} onClick={(e) => {
                            e.preventDefault();
                            handleTabChange('history');
                        }}>
                            <i className="bi bi-clock-history"></i>
                            <span>History</span>
                        </a>

                        {!agentId && (
                            <a href="#" className={isActive('agents')} onClick={(e) => {
                                e.preventDefault();
                                handleTabChange('agents')
                            }}>
                                <i className="bi bi-robot"></i>
                                <span>Agents</span>
                            </a>
                        )}

                        <a href="#" className={isActive('settings')} onClick={(e) => {
                            e.preventDefault();
                            handleTabChange('settings')
                        }}>
                            <i className="bi bi-gear"></i>
                            <span>Settings</span>
                        </a>
                    </div>
                    {renderTabContent({activeTab, histories, historySelection, chatPayload, setChatPayload, type})}
                </>
            ) : (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col px-4 mt-3">
                                {activeTab === 'settings' ? (
                                    <NewAgentModal />
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-outline-light"
                                        style={{ width: '100%', color: "#BEC9D9" }}
                                        onClick={activeTab === 'settings' ? test : resetChat}
                                    >
                                        <i className="bi bi-plus-lg"></i>New Chat
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {renderTabContent({activeTab, histories, historySelection, chatPayload, setChatPayload, type})}
                </>
            )}
        </nav>
    )
}

const renderTabContent = ({activeTab, histories, historySelection, chatPayload, setChatPayload, type}: any) => {
    switch (activeTab) {
        case 'history':
            return <HistoryMenu histories={histories} historySelection={historySelection} chatPayload={chatPayload} />;
		case 'agents':
			return <AgentMenu chatPayload={chatPayload} />;
        case 'tools':
            return <div>Tools Content</div>;
        case 'files':
            return <FilesMenu />;
        case 'settings':
            return (
                <SettingsMenu chatPayload={chatPayload} setChatPayload={setChatPayload} type={type} />
            );
        default:
            return <div>Default Content</div>;
    }
};
