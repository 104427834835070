import React, { useEffect, useState } from 'react';
import PublicLayout from '../layouts/PublicLayout';
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from '../config';
import { useAuthContext } from '../contexts/AuthContext';

export default function Auth() {
    let history = useNavigate();
    const location = useLocation();
    const [authData, setAuthData] = useState(null);
    const { setBotAccessToken, logout } = useAuthContext();

    // Parse the query string
    const searchParams = new URLSearchParams(location.search);
    const authCode = searchParams.get("code");

    useEffect(() => {
        const fetchAuthData = async () => {
            if (authCode && !authData) {
                try {
                    const response = await fetch(`${API_URL}/auth?code=${authCode}`);
                    const data = await response.json();
                    setAuthData(data);
                    if (data.access_token) {
                        localStorage.setItem('botAccessToken', data.access_token);
                        window.location.href = `/`
                    }
                } catch (error: any) {
                    console.error('Error fetching auth data:', error);
                    alert(error.message);
			        logout();
                }
            }
        };

        fetchAuthData();
    }, [authCode]);

    return (
        <PublicLayout>
            <div className='row'>
                <div className="col text-center mt-5">
                    {authData ? (
                        <>
                            <h1>Authenticated!</h1>
                            {/* <p>Access Token: {authData}</p> */}
                        </>
                    ) : <h1>Not Authenticated</h1>}
                </div>
            </div>
        </PublicLayout>
    );
}