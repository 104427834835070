import { useEffect, useState } from "react";
import { useChatContext } from "../../contexts/ChatContext";
import { truncate } from "../../utils/format";

const HistoryMenu = ({ histories, historySelection, chatPayload }: any) => {
    const { handleRemove } = useChatContext();
    const [remove, setRemove] = useState({
		id: '',
		submit: false,
	});

    useEffect(() => {
		if(remove.submit) {
			handleRemove(remove.id)
		}
	}, [remove.submit])
    return (
		<div className="sidebar-menu" style={{ height: 'calc(100% - 115px)', overflowY: 'auto' }}>
            <div className="sidebarMenuScroll os-host os-theme-dark os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
                <div className="os-resize-observer-host observed">
                    <div className="os-resize-observer" style={{ left: '0px', right: 'auto' }}></div>
                </div>
                <div className="os-padding">
                    <div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}>
                        <div className="os-content" style={{ padding: '0px', height: '100%', width: '100%' }}>
                            <ul>
                                {histories.map((item: any, index: number) => (
                                    <li
                                        className={item._id === chatPayload._id ? 'active-page-link' : ''}
                                        key={index}
                                        style={{ display: 'flex', alignItems: 'center', position: 'relative', margin: 0}}
                                        onClick={async () => await historySelection(item)}
                                    >
                                        <a href="#" onClick={(event) => event.preventDefault()} style={{ padding: '5px', width: "100%" }}>
                                            <span style={{ fontSize: '10px', position: 'absolute', top: 2, right: 15 }}>
                                                Query Count: {(item.messages.length -1) / 2}
                                            </span>
                                            <i className="bi bi-chat-right" style={{ marginRight: '5px', fontSize: '20px' }}></i>
                                            <div style={{ flex: 1 }}>
                                                <span
                                                    className="menu-text"
                                                    style={{ fontSize: '14px', color: '#F8F8F8', display: 'block', marginBottom: '3px', marginTop: '10px' }}
                                                    title={item.title || item.messages[item.messages.length - 1].content} // Full message as tooltip
                                                >
                                                    {truncate(item.title || item.messages[item.messages.length - 1].content, 32)}
                                                </span>

                                                <span style={{ display: 'block', fontSize: '10px' }}>
													{new Intl.DateTimeFormat('default', {
														year: 'numeric',
														month: 'short',
														day: 'numeric',
														hour: '2-digit',
														minute: '2-digit',
														hour12: true
													}).format(new Date(item.updated_at * 1000)) || 'No date'} {/* Formats the current date */}
                                                </span>
                                            </div>
                                        </a>
                                        {chatPayload._id === item._id && (
                                            <>
                                                {remove && remove.id === item._id ? (
                                                    <>
                                                        {/* Convert to Bootstrap icons or custom components */}
                                                        <span
                                                            className="position-absolute"
                                                            style={{ right: 45, bottom: 2, color: 'red' }}
                                                            onClick={()=>setRemove({id: '', submit: false})}
                                                        >
                                                            <i className="bi bi-x-circle"></i>
                                                        </span>
                                                        <span
                                                            className="position-absolute"
                                                            style={{ right: 25, bottom: 3, color: '#2C998A' }}
                                                            onClick={()=>setRemove({...remove, submit: true})}
                                                        >
                                                            <i className="bi bi-check-circle-fill"></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* Convert to Bootstrap icons or custom components */}
                                                        <span
                                                            className="position-absolute"
                                                            style={{ right: 45, bottom: 2, color: 'white' }}
                                                            onClick={()=>alert("EDIT")}
                                                        >
                                                            <i className="bi bi-pencil-square"></i>
                                                        </span>
                                                        <span
                                                            className="position-absolute"
                                                            style={{ right: 25, bottom: 3, color: 'white' }}
                                                            onClick={()=>setRemove({id: item._id, submit: false})}
                                                        >
                                                            <i className="bi bi-trash3"></i>
                                                        </span>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistoryMenu;